// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  credentials: {
    email: "",
    password: "",
  },
  baseUrl: process.env['NG_APP_BASE_URL'] || "https://api.staging.zonedeliveryservices.com",
  commonUrl: process.env['NG_APP_COMMON_URL'] || "/common/v2",
  ondemandUrl: process.env['NG_APP_ONDEMAND_URL'] || "/ondemand/v2",
  merchantUrl: process.env['NG_APP_MERCHANT_URL'] || "https://merchant.pharmacy.staging.zonedeliveryservices.com",
  socketUrl: process.env['NG_APP_SOCKET_URL'] || "https://apiondemand.staging.zonedeliveryservices.com/",
  v3OndeamndUrl: process.env['NG_APP_V_30_ONDEMAND_URL'] || "/ondemand/v3",
  errorHandlerArr: process.env['NG_APP_ERROR_HANDLER_ARR'] || [],
  apiKey: process.env['NG_APP_API_KEY'] || "AIzaSyCsPriWr1muoozMnLsy5bFQRgLlZ88bZfU",
  superAdminAppId: process.env['NG_APP_SUPER_ADMIN_APP_ID'] || 'bgfbdsa93upqr1bl36x7dlq7',
  pharmacyAppId: process.env['NG_APP_PHARMACY_APP_ID'] || 'lfzyulw7hm4qitee9h13pr50',
  // walletEmail: process.env['NG_APP_WALLET_EMAIL'] || 'adminpharmacy@zonedeliveryservices.com'
  walletEmail: process.env['NG_APP_WALLET_EMAIL'] || 'superadmin@zonedeliveryservices.com',
  environment: process.env['NG_APP_ENVIRONMENT'] || 'staging'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
